import { Blockchain, ChainEnvironment } from "../blockchains.config";
import { Strategy } from "../contracts";
import { EnvVar } from "../strategies.config";

const buildEnvVarKey = (strategy: Strategy, envVar: EnvVar): string => `REACT_APP_${strategy}_${envVar}`;

const MissingEnvVarError = (strategy: Strategy, envVar: EnvVar) =>
    new Error(`Missing env var: ${buildEnvVarKey(strategy, envVar)}`);

const IncorrectEnvVarError = (strategy: Strategy, envVar: EnvVar, value: string | null) =>
    new Error(`Incorrect env var value: ${buildEnvVarKey(strategy, envVar)}=${value}`);

export const UnsupportedChainEnvError = (strategy: Strategy, blockchain: Blockchain, chainEnv: ChainEnvironment) =>
    new Error(
        `Unsupported chain environment for ${strategy}: Blockchain - ${blockchain}, chain environment - ${chainEnv}`,
    );

export const readEnvVar = <R extends boolean = true>(
    strategy: Strategy,
    envVar: EnvVar,
    isRequired: R = true as R,
): R extends true ? string : string | null => {
    const key = buildEnvVarKey(strategy, envVar);
    const envVarValue = process.env[key] ?? null;

    if (isRequired && envVarValue === null) throw MissingEnvVarError(strategy, EnvVar.ChainEnvironment);
    return envVarValue as R extends true ? string : string | null;
};

const isChainEnvironment = (str: string): str is ChainEnvironment =>
    str === "mainnet" || str === "testnet" || str === "local";

export const readChainEnvironment = (strategy: Strategy): ChainEnvironment => {
    const chainEnvStr = readEnvVar(strategy, EnvVar.ChainEnvironment);
    if (!isChainEnvironment(chainEnvStr)) throw IncorrectEnvVarError(strategy, EnvVar.ChainEnvironment, chainEnvStr);

    return chainEnvStr;
};
