import { useColorMode } from "@chakra-ui/react";
import { memoizedGet as get } from "@chakra-ui/utils";
import { hexToRGBA } from "../utils/color";
import { theme } from ".";
import { SemanticTokenColor } from "./semanticTokens";

export const getSemanticTokenColorValue = (color: SemanticTokenColor, fallback?: string): string | undefined => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { colorMode } = useColorMode();
    return color[`_${colorMode}` as keyof SemanticTokenColor] ?? fallback;
};

export const getColorValue = (color: string, fallback?: string): string | undefined => {
    const colorValue = get(theme, `colors.${color}`) ?? get(theme, `semanticTokens.colors.${color}`);
    return typeof colorValue === "object" ? getSemanticTokenColorValue(colorValue, fallback) : colorValue ?? fallback;
};

export const getAlphaColor = (color: string, alpha: number, fallback: string = ""): string => {
    const colorValue = getColorValue(color, fallback);
    return colorValue && colorValue.startsWith("#") ? hexToRGBA(colorValue, alpha) : fallback;
};
