import { appConfig } from "../../config";
import { RestClient } from "../client";
import { MorphoResponse } from "./types";

interface IMorphoApiService {
    getApy: (address: string) => Promise<MorphoResponse>;
}

export class MorphoApiService implements IMorphoApiService {
    constructor(private restClient = new RestClient(appConfig.morphoApiBaseUrl)) { }

    async getApy(address: string): Promise<MorphoResponse> {
        const query = `
            query {
                vaults(
                    where: { address_in: ["${address}"] }
                ) {
                    items {
                        symbol
                        name
                        state {
                            apy
                            netApy
                            totalAssets
                            totalAssetsUsd
                        }
                    }
                }
            }
        `;

        const response = await this.restClient.post<{ query: string }, MorphoResponse>('/graphql', {
            query: query
        });
        return response;
    }
}