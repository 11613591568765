import { ReactComponent as LogoSwissBorg } from "./assets/Logo.svg";
import {
    Flex,
    HStack,
} from "@chakra-ui/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export const Header = () => {
    return (
        <Flex as={"header"} h={"8.8rem"} alignItems={"center"} justifyContent={"space-between"}>
            <HStack spacing={{ base: 5, lg: 14 }}>
                <LogoSwissBorg />
            </HStack>

            <HStack spacing={4}>
                <ConnectButton />
            </HStack>
        </Flex>
    );
};
