import React from "react";
import { StrategyExtras, StrategyHeader, Wallet } from "../components";
import { Strategy } from "../contracts";
import { useMetaMorphoStatsStore } from "../stores/metaMorphoStore";
import { AssetSymbol } from "../theme";
import { Column, Template } from "../uikit/template";
import { Maybe } from "true-myth";
import { useAccount } from "wagmi";
import { useStrategyClient } from "../contracts/clients/useStrategyClient";
import { useApyData } from "../contracts/hooks/useApyData";

const STATS_REFRESH_INTERVAL = 10000;

interface Props<T extends Strategy> {
    strategy: T;
    strategySymbol: AssetSymbol;
}

export const MetaMorphoPage = <T extends Strategy>({
    strategy,
    strategySymbol
}: Props<T>) => {
    const client = useStrategyClient(strategy);
    const { address } = useAccount();
    const { apyData } = useApyData(strategy);
    const resetStats = useMetaMorphoStatsStore(state => state.resetStats);
    const refreshStats = useMetaMorphoStatsStore(state => state.refreshStats);
    const refreshStrategyStats = () => refreshStats(client, Maybe.of(address));

    React.useEffect(() => {
        resetStats();
        refreshStrategyStats();
        const interval = setInterval(refreshStrategyStats, STATS_REFRESH_INTERVAL);
        return () => {
            clearInterval(interval);
        };
    }, [client, address]);

    return (
        <>
            <Template rowGap={{ base: "1.6rem", sm: "2rem", md: "2.6rem", xl: "3.2rem" }}>
              <Column width={{ base: "100%", lg: "58.4325%", xl: "57.4325%" }}>
                <StrategyHeader symbol={strategySymbol} apyData={Maybe.of(apyData?.data.vaults.items[0].state.netApy)} />
              </Column>
              <Column width={{ base: "100%", lg: "38.1622%", xl: "37.1622%" }}>
                <Wallet<T> strategy={strategy} />
                <StrategyExtras strategy={strategy} />
              </Column>
            </Template>
        </>
    );
};
