import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export class RestClient {
    private client;

    constructor(apiUrl: string) {
        this.client = axios.create({
            baseURL: apiUrl,
        });
        this.client.interceptors.response.use(
            res => res,
            (error: AxiosError) => Promise.reject(error),
        );
    }

    public get<T>(path: string, config?: AxiosRequestConfig): Promise<T> {
        return this.client.get<T>(path, config).then(this.mapToData);
    }

    public post<T, U>(path: string, data?: T, config?: AxiosRequestConfig): Promise<U> {
        return this.client.post<U>(path, data, config).then(this.mapToData);
    }

    public put<T, U>(path: string, data?: T, config?: AxiosRequestConfig): Promise<U> {
        return this.client.put<U>(path, data, config).then(this.mapToData);
    }

    private mapToData = <T>(response: AxiosResponse<T>): T => response.data;
}