import { Big, BigSource } from "big.js";
import { BigNumber } from "ethers";
import { Result } from "true-myth";

/**
 The negative exponent value at which toString returns exponential notation.
 Set to -18 because the smallest possible ETH amount is 1 Wei === 1e-18
 */
Big.NE = -18;

export type BigDecimal = Big;
export type BigDecimalSource = BigSource | BigNumber;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BigDecimal = Big;

export const bigDecimal = (src: BigSource): BigDecimal => new Big(src);

export const safeParseBigDecimal = (src: BigDecimalSource): Result<BigDecimal, string> => {
    try {
        const big = bigDecimal(BigNumber.isBigNumber(src) ? src.toString() : src);
        return Result.ok(big);
    } catch (e) {
        return Result.err(`Failed to parse "${src}" to Big Decimal: ${(e as Error).message ?? "unknown error"}`);
    }
};

export const toBigNumber = (src: BigDecimal): BigNumber => BigNumber.from(src.toString());

export const BIG_ZERO: Big = bigDecimal(0);
export const BIG_MAX_UINT256: Big = bigDecimal(
    "115792089237316195423570985008687907853269984665640564039457584007913129639935",
);
