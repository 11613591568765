import React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import { Icon, IconName } from "./icon";
import { assets, AssetSymbol } from "../theme";
import { Maybe } from "true-myth";

const styles = {
    sm: {
        w: "2.4rem",
        h: "2.4rem",
        borderRadius: "0.8rem",
        icon: {
            w: "0.8rem",
            h: "0.8rem",
        },
    },
    md: {
        w: "4.8rem",
        h: "4.8rem",
        borderRadius: "1.2rem",
        icon: {
            w: "3.2rem",
            h: "3.2rem",
        },
    },
    lg: {
        w: "7.2rem",
        h: "7.2rem",
        borderRadius: "1.6rem",
        icon: {
            w: "5.6rem",
            h: "5.6rem",
        },
    },
    menu: {
        w: "2.4rem",
        h: "2.4rem",
        borderRadius: "0.8rem",
        icon: {
            w: "1.6rem",
            h: "1.6rem",
        },
    },
} as const;

export interface AssetProps extends FlexProps {
    symbol: AssetSymbol;
    size?: keyof typeof styles;
}

export const Asset = ({ symbol, size = "md", ...props }: AssetProps) => {
    const asset = Maybe.of(assets[symbol]);
    return (
        <Flex
            sx={styles[size]}
            justifyContent={"center"}
            alignItems={"center"}
            bg={asset.mapOr<string>("gray.500", ({ color }) => color)}
            {...props}
        >
            <Icon
                sx={styles[size].icon}
                name={asset.mapOr<IconName>("generic.cash", ({ icon }) => icon)}
                fill={"white"}
            />
        </Flex>
    );
};
