import { equals } from "ramda";
import { AccountBalance, Strategy, StrategyClient, Token } from "../contracts";
import { BigDecimal, BIG_ZERO } from "../utils/bigDecimal";
import { Maybe } from "true-myth";
import { create } from "zustand";

interface StrategyStats {
    isLoading: boolean;
    depositToken: Token;
    iouToken: Token;
    totalValueLocked: BigDecimal;
    allowance: BigDecimal;
    balance: AccountBalance;
    depositTokenBalance: BigDecimal;
}

interface StrategyStatsState extends StrategyStats {
    setStat: <T extends keyof StrategyStatsState>(key: T) => (value: StrategyStatsState[T]) => void;
    refreshStats: <T extends Strategy>(client: StrategyClient<T>, account: Maybe<string>) => void;
    resetStats: () => void;
}

const initialStrategyStats: StrategyStats = {
    isLoading: true,
    depositToken: {
        symbol: "",
        decimals: 0,
    },
    iouToken: {
        symbol: "",
        decimals: 0,
    },
    totalValueLocked: BIG_ZERO,
    allowance: BIG_ZERO,
    balance: {
        shares: BIG_ZERO,
        amount: BIG_ZERO,
    },
    depositTokenBalance: BIG_ZERO,
};

const handleRefreshError = (statField: string) => (err: any) => {
    // TODO We might want to display that or do sth about it
    console.error(`Failed to refresh ${statField}`, err);
};

export const useMetaMorphoStatsStore = create<StrategyStatsState>()((set, get) => ({
    ...initialStrategyStats,

    setStat:
        <T extends keyof StrategyStatsState>(key: T) =>
        (value: StrategyStatsState[T]) =>
            !equals(get()[key], value) && set({ [key]: value as any }),

    refreshStats: <T extends Strategy>(client: StrategyClient<T>, account: Maybe<string> = Maybe.nothing()) => {
        const updateStats = get().setStat;

        Promise.allSettled([
            client.getDepositToken().then(updateStats("depositToken")).catch(handleRefreshError("Deposit Token")),
            client.getIOUToken().then(updateStats("iouToken")).catch(handleRefreshError("IOU Token")),
            client.getTotalBalance().then(updateStats("totalValueLocked")).catch(handleRefreshError("TVL")),

            ...account.mapOr([], acc => [
                client.getAllowance(acc).then(updateStats("allowance")).catch(handleRefreshError("Allowance")),
                client.getAccountBalance(acc).then(updateStats("balance")).catch(handleRefreshError("Account Balance")),
                client
                    .getDepositTokenBalance(acc)
                    .then(updateStats("depositTokenBalance"))
                    .catch(handleRefreshError("Deposit Token Balance")),
            ]),
        ]).then(() => updateStats("isLoading")(false));
    },

    resetStats: () => set(initialStrategyStats),
}));
