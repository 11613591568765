import { Card, Asset, CardProps, Stat } from "../../uikit"
import { Flex, HStack, Stack, Text, Center, Spinner } from "@chakra-ui/react";
import { Heading } from "../../uikit/typography"
import { useMetaMorphoStatsStore } from "../../stores/metaMorphoStore";
import { shallow } from "zustand/shallow";
import { formatNumber } from "../../utils/format";
import { assets, AssetSymbol } from "../../theme";
import { MorphoResponse } from "../../rest/morpho";
import { Maybe } from "true-myth";

interface Props extends CardProps {
    symbol: AssetSymbol;
    apyData: Maybe<number>;
}

export const StrategyHeader = ({ symbol, apyData }: Props) => {
    const stats = useMetaMorphoStatsStore(
        state => ({ tvl: state.totalValueLocked, depositToken: state.depositToken }),
        shallow,
    );

    return (
        <Card>
            <Stack spacing={{ base: 4, sm: 6 }}>
                <Flex justifyContent={"space-between"} flexDirection={{ base: "column", sm: "row" }} gap={"1.6rem"}>
                    <HStack spacing={4}>
                        <Asset symbol={symbol} size={"lg"} />
                        <Stack>
                            <Heading as={"h2"}>Morpho</Heading>
                            <HStack>
                                <Text color={"gray.500"}>TVL</Text>
                                <Text fontWeight={"semibold"}>
                                    {formatNumber(stats.tvl.toNumber(), {
                                        suffix: assets[symbol].displaySymbol,
                                        joinSeparator: " ",
                                        maximumFractionDigits: 2,
                                    })}
                                </Text>
                            </HStack>
                        </Stack>
                    </HStack>
                    {apyData
                        .map(apy => (
                            <Stat
                                key={apy}
                                label={"APY"}
                                value={formatNumber(apy * 100, { suffix: "%", maximumFractionDigits: 2 })}
                                headingSize={{ base: "2rem", sm: "3.8rem" }}
                                textAlign={"right"}
                            />
                        ))
                        .unwrapOr(
                            <Center w={"7.2rem"} h={"7.2rem"}>
                                <Spinner />
                            </Center>,
                        )}
                </Flex>
            </Stack>
        </Card>
    )
}