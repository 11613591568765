import { Card, CardProps } from "../../uikit"
import {
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from "@chakra-ui/react";
import { BigDecimal } from "../../utils/bigDecimal";
import { useAccount } from "wagmi";
import React from "react";
import { Maybe } from "true-myth";
import { Strategy, TransactionError } from "../../contracts";
import { useStrategyClient } from "../../contracts/clients/useStrategyClient";
import { useMetaMorphoStatsStore } from "../../stores/metaMorphoStore";
import { shallow } from "zustand/shallow";
import { usePromise } from "react-use-promise-matcher";
import { ContractTransaction } from "ethers";
import { TransactionForm } from "./components/TransactionForm";
import { AssetSymbol } from "../../theme";
import { noop } from "lodash";
import { AppConfigContext } from "../../context";

interface Props<T extends Strategy> extends CardProps {
    strategy: T;
}
export const Wallet = <T extends Strategy>({
    strategy
}: Props<T>) => {
    const { address, isConnected } = useAccount();

    const client = useStrategyClient<T>(strategy);
    const stats = useMetaMorphoStatsStore(
        state => ({
            isLoading: state.isLoading,
            depositToken: state.depositToken,
            iouToken: state.iouToken,
            tvl: state.totalValueLocked,
            allowance: state.allowance,
            balance: state.balance,
            depositTokenBalance: state.depositTokenBalance,
            refreshStats: state.refreshStats,
        }),
        shallow,
    );

    const { strategies } = React.useContext(AppConfigContext);
    const maybeAccount = React.useMemo(() => Maybe.of(address), [address]);
    const [isDepositDisabled, setIsDepositDisabled] = React.useState(false);
    const [isWithdrawalDisabled, setIsWithdrawalDisabled] = React.useState(false);

    const [approveResult, approveSpend] = usePromise<ContractTransaction, [string, BigDecimal], TransactionError>(
        client.approveSpend,
    );

    const [depositResult, depositToStrategy] = usePromise<
        ContractTransaction,
        [string, BigDecimal],
        TransactionError
    >(client.deposit);

    const [withdrawResult, withdrawFromStrategy] = usePromise<
        ContractTransaction,
        [string, BigDecimal],
        TransactionError
    >(client.withdraw);

    React.useEffect(() => {
        setIsDepositDisabled(stats.depositTokenBalance.eq(0))
        setIsWithdrawalDisabled(stats.balance.shares.eq(0))
    })

    return (
        <Card>
            <Stack spacing={4}>
                <Tabs variant={"solid-rounded"} isFitted>
                    <TabList>
                        <Tab>Deposit</Tab>
                        <Tab>Withdrawal</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <TransactionForm
                                transactionType={"deposit"}
                                availableAmount={stats.depositTokenBalance}
                                availableAmountSymbol={stats.depositToken.symbol as AssetSymbol}
                                allowance={stats.allowance}
                                onTransaction={(amount: BigDecimal) =>
                                    maybeAccount.match({
                                        Just: account => {
                                            stats.allowance.lt(amount) ? approveSpend(account, amount) : depositToStrategy(account, amount)
                                        },
                                        Nothing: noop,
                                    })
                                }
                                approveResult={approveResult}
                                transactionResult={depositResult}
                                isConnected={isConnected}
                                isDisabled={isDepositDisabled}
                                isLoading={approveResult.isLoading || depositResult.isLoading}
                                minAmount={strategies[strategy].minDepositAmount}
                                maxAmount={strategies[strategy].maxDepositAmount}
                            />
                        </TabPanel>
                        <TabPanel>
                            <TransactionForm
                                transactionType={"withdrawal"}
                                availableAmount={stats.tvl}
                                availableAmountSymbol={stats.depositToken.symbol as AssetSymbol}
                                onTransaction={(amount: BigDecimal) =>
                                    maybeAccount.match({
                                        Just: account => {withdrawFromStrategy(account, amount)},
                                        Nothing: noop,
                                    })
                                }
                                transactionResult={withdrawResult}
                                isConnected={isConnected}
                                isDisabled={isWithdrawalDisabled}
                                isLoading={withdrawResult.isLoading}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Stack>
        </Card>
    )
}
