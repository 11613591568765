export const supportedChainIds = [1, 1337, 43114, 43113, 56, 97] as const;
export type ChainId = (typeof supportedChainIds)[number];

interface ChainEnvironmentConfig {
    id: ChainId;
    explorerUrl?: string;
}

interface ChainConfig {
    mainnet: ChainEnvironmentConfig;
    testnet?: ChainEnvironmentConfig;
    local?: ChainEnvironmentConfig;
}

export type ChainEnvironment = NonNullable<keyof ChainConfig>;

export enum Blockchain {
    Ethereum = "Ethereum",
    Avalanche = "Avalanche",
    BSC = "BSC",
}

type BlockchainsConfig = Record<Blockchain, ChainConfig>;

export const blockchainsConfig: BlockchainsConfig = {
    Avalanche: {
        mainnet: {
            id: 43114,
            explorerUrl: "https://snowtrace.io/",
        },
        testnet: {
            id: 43113,
        },
    },
    Ethereum: {
        mainnet: {
            id: 1,
            explorerUrl: "https://etherscan.io/",
        },
        local: {
            id: 1337,
        },
    },
    BSC: {
        mainnet: {
            id: 56,
        },
        testnet: {
            id: 97,
        },
    },
};
