import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { ThemeColor } from "../theme/palette";
import { semanticTokens } from "../theme/semanticTokens";

export interface CardProps extends BoxProps {}

export const Card = ({ children, ...props }: CardProps) => {
    return (
        <Box
            bg={ThemeColor.CARDS}
            p={semanticTokens.sizes.cardPadding}
            borderRadius={semanticTokens.sizes.cardBorderRadius}
            {...props}
        >
            {children}
        </Box>
    );
};
