import React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";

interface TemplateContext {
    rowGap?: FlexProps["rowGap"];
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TemplateContext = React.createContext<TemplateContext>({});

export const Template = ({ children, ...props }: FlexProps) => (
    <TemplateContext.Provider value={{ rowGap: props.rowGap }}>
        <Flex direction={{ base: "column", lg: "row" }} justifyContent={"space-between"} {...props}>
            {children}
        </Flex>
    </TemplateContext.Provider>
);
