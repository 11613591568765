import { MetaMorphoAbi } from "./generated-types/MetaMorphoAbi";
import { BigDecimal } from "../utils/bigDecimal";
import { BaseContract, ContractTransaction, Transaction } from "ethers";

export enum Strategy {
    MetaMorpho = "METAMORPHO",
}

type AbiTypeMapEntry<T extends Strategy, B extends BaseContract> = Record<T, B>;

type AbiTypeMap = AbiTypeMapEntry<Strategy.MetaMorpho, MetaMorphoAbi>

export type StrategyAbi<T extends Strategy> = AbiTypeMap[T];

export interface SlippageRange {
    minPercentage: BigDecimal;
    maxPercentage: BigDecimal;
}

export interface TVD {
    tvd: BigDecimal;
    usdEquivalent: BigDecimal;
}

export interface Token {
    symbol: string;
    decimals: number;
}

export interface AccountBalance {
    shares: BigDecimal;
    amount: BigDecimal;
}

export interface StrategyClient<T extends Strategy> {
    contract: StrategyAbi<T>;
    getDepositToken: () => Promise<Token>;
    getIOUToken: () => Promise<Token>;
    getTotalBalance: () => Promise<BigDecimal>;
    getAllowance: (account: string) => Promise<BigDecimal>;
    getAccountBalance: (account: string) => Promise<AccountBalance>;
    getDepositTokenBalance: (account: string) => Promise<BigDecimal>;
    approveSpend: (account: string, amount?: BigDecimal) => Promise<ContractTransaction>;
    deposit: (account: string, amount: BigDecimal, signature?: string) => Promise<ContractTransaction>;
    withdraw: (account: string, amount: BigDecimal, slippage?: BigDecimal) => Promise<ContractTransaction>;
}

export interface TransactionError {
    reason: string;
    code: string;
    transaction: Transaction;
    action?: string;
    method?: string;
}
